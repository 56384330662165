import {
    create,
    getAvailabilities,
    getItem,
    getList,
    remove,
    setAvailability,
    update,
    updateAvailability
} from "@/api/event";

const state = {
    list: [],
    historyList: [],
    currentEventAvailabilities: [],
}

const mutations = {
    SET_LIST(state, value) {
        state.list = value
    },
    SET_HISTORY_LIST(state, value) {
        state.historyList = value
    },
    SET_AVAILABILITIES(state, value) {
        state.currentEventAvailabilities = value
    },
}

const actions = {
    list({commit}, data = null) {
        return new Promise((resolve, reject) => {
            getList(data).then(response => {
                try {

                    commit('SET_LIST', response.events)
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    historyList({commit}, data = null) {
        return new Promise((resolve, reject) => {
            getList(data).then(response => {
                try {

                    commit('SET_HISTORY_LIST', response.events)
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    delete({commit}, item) {
        return new Promise((resolve, reject) => {
            remove(item).then(() => {
                try {
                    commit('SET_LIST', state.list.filter(e => e.id !== item.id))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    get({commit}, itemId) {
        return new Promise((resolve, reject) => {
            getItem(itemId).then((result) => {
                try {
                    let newList = state.list.filter(el => el.id !== result.event.id)
                    newList.push(result.event)
                    commit('SET_LIST', newList)
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    getAvailabilities({commit}, item) {
        return new Promise((resolve, reject) => {
            getAvailabilities(item).then((result) => {
                try {
                    commit('SET_AVAILABILITIES', result.availabilities)
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {
                reject(error)
            });

        })
    },

    setAvailability({commit}, payload) {
        return new Promise((resolve, reject) => {
            setAvailability(payload.item, payload.status).then((result) => {
                try {
                    commit('SET_AVAILABILITIES', state.currentEventAvailabilities.map(el => el.user.id === result.availability.user.id ? result.availability : el))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {
                reject(error)
            });

        })
    },

    create({commit}, item) {
        return new Promise((resolve, reject) => {
            create(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.concat(result.event))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    update({commit}, item) {
        return new Promise((resolve, reject) => {
            update(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.map(el => el.id === result.event.id ? result.event : el))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateAvailability({commit, dispatch}, payload) {
        const item = payload.item;
        const availability = payload.status;

        const avItem = {
            "availability": {
                "status": availability
            }
        };

        return new Promise((resolve, reject) => {
            updateAvailability(item, avItem).then((response) => {
                try {
                    commit('SET_LIST', state.list.map(e => e.id === item.id ? response.event : e))
                    dispatch('getAvailabilities', item);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}