<script setup>
import {useStore} from "vuex";
import router from "@/router";
import process from "process";
import {computed, onMounted} from "vue";

const store = useStore();
const env = computed(() => process.env);
const current = computed(() => store.state.user.user);

const logout = () => {
  store.dispatch('user/logout')
      .then(() => {
        router.push('/login');
      });
}
onMounted(() => {
  if (!current.value) {
    store.dispatch('user/current');
  }
});
</script>
<template>
  <nav
      class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top position-sticky bg-white left-auto top-2 z-index-sticky position-absolute px-4 mx-0 w-100 z-index-2">
    <div class="container-fluid">
      <button
          class="navbar-toggler d-block me-2"
          type="button"
          @click.stop.prevent="store.state.showSidenav = true"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link to="/" class="navbar-brand me-auto ms-lg-0 ms-3 text-uppercase fw-bold text-white">
        {{ env.VUE_APP_APP_NAME }}
      </router-link>

      <div class="" id="topNavBar">
        <router-link :to="{ name: 'users.edit', params: { id: current?.id }}" class="white me-4"
        v-if="current">
          <i class="fa-solid fa-user fa-xl"></i>
        </router-link>
        <a class="white" href="#" @click.stop.prevent="logout()"><i class="fa-solid fa-right-from-bracket fa-xl"></i></a>
      </div>
    </div>
  </nav>
</template>
