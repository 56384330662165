<script setup>
import {computed, inject, onMounted, ref} from "vue";
import store from "@/store";
import EventCard from "@/components/home/event/EventCard.vue";
import {availabilityStatus, eventType, status} from "@/utils/constants";

const list = computed(() => {
  let list = store.state.event.list

  //Solo date future
  list = list.filter((el) => new Date(el.start_date) >= new Date(new Date().toDateString()));

  //Filtro per tipologia
  if (typeFilter.value !== null) {
    list = list.filter((el) => el.type === typeFilter.value);
  }

  //Filtro per stato
  if (statusFilter.value !== null) {
    list = list.filter((el) => el.status === statusFilter.value);
  }

  //Filtro per blocccate
  if (lockFilter.value !== null) {
    list = list.filter((el) => el.lock === lockFilter.value);
  }

  //Filtro per ricerca
  if (searchFilter.value !== null) {
    list = list.filter((el) => (el.short_description ?? '').toLowerCase().includes(searchFilter.value.toLowerCase()) || el.location.toLowerCase().includes(searchFilter.value.toLowerCase()));
  }

  //Filtro per disponibilità
  if (attendanceFilter.value !== null) {
    if (attendanceFilter.value === availabilityStatus.TO_CHECK) {
      list = list.filter((el) => el.user_availability === null && (el.lock === false || el.lock === null));
    } else if (attendanceFilter.value === availabilityStatus.PRESENT) {
      list = list.filter((el) => el.user_availability === attendanceFilter.value);
    } else {
      list = list.filter((el) => el.user_availability === availabilityStatus.ABSENT || (el.user_availability !== availabilityStatus.PRESENT && el.lock === true));
    }
  }
  return list
});

Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const currentDate = new Date();
const oneWeekLater = currentDate.addDays(7);
const twoMonthsLater = currentDate.addDays(60);
const gigNoResponse = computed(() => {
  return store.state.event.list.filter((el) => {
    const eventStartDate = new Date(el.start_date);
    return eventStartDate <= twoMonthsLater &&
        el.type === eventType.GIG &&
        (el.user_availability === null && (el.lock === false || el.lock === null))
  }).length
});

const rehearsalNoResponse = computed(() => {
  return store.state.event.list.filter((el) => {
    const eventStartDate = new Date(el.start_date);
    return eventStartDate <= oneWeekLater &&
        el.type === eventType.REHEARSAL &&
        (el.user_availability === null && (el.lock === false || el.lock === null))
  }).length
});

const swal = inject('$swal')
const typeOption = [{'label': 'Tutte', 'value': null}, {'label': 'Concerto', 'value': eventType.GIG}, {
  'label': 'Prova',
  'value': eventType.REHEARSAL
}];
const statusOption = [{'label': 'Tutte', 'value': null}, {
  'label': 'Confermate',
  'value': status.CONFIRMED
}, {'label': 'Forse', 'value': status.NOT_CONFIRMED}];
const lockOption = [
  {'label': 'Tutte', 'value': null},
  {'label': 'Si', 'value': true},
  {'label': 'No', 'value': false},
];
const attendanceOption = [
  {'label': 'Tutte', 'value': null},
  {'label': 'Presente', 'value': availabilityStatus.PRESENT},
  {'label': 'Assente', 'value': availabilityStatus.ABSENT},
  {'label': 'Da verificare', 'value': availabilityStatus.TO_CHECK},
];
const typeFilter = ref(null);
const statusFilter = ref(null);
const lockFilter = ref(null);
const attendanceFilter = ref(null);
const searchFilter = ref('');

onMounted(() => {
  store.dispatch('event/list', {'next': true})
      .catch((response) => {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: response
        });
      });
})
</script>

<template>
  <div class="alert alert-danger m-2" role="alert" v-if="gigNoResponse > 0"
       @click.stop.prevent="typeFilter = eventType.GIG; statusFilter = null; attendanceFilter = availabilityStatus.TO_CHECK; searchFilter = ''; lockFilter = null">
    Non hai risposto per {{ gigNoResponse }} concerti! Clicca per verificare
  </div>
  <div class="alert alert-warning m-2" role="alert" v-if="rehearsalNoResponse > 0"
       @click.stop.prevent="typeFilter = eventType.REHEARSAL; statusFilter = null; attendanceFilter = availabilityStatus.TO_CHECK; searchFilter = ''; lockFilter = null">
    Non hai risposto per {{ rehearsalNoResponse }} prove! Clicca per verificare
  </div>
  <div class="mx-2 my-2">
    <div class="me-2" style="display:inline-block;">
      <label class="form-label" for="typeFilter">Tipologia: </label>
      <select v-model="typeFilter" class="form-select" name="typeFilter">
        <option
            v-for="el in typeOption"
            :key="el.value"
            :label="el.label"
            :value="el.value"
        />
      </select>
    </div>
    <div class="me-2" style="display:inline-block;">
      <label class="form-label" for="statusFilter">Stato: </label>
      <select v-model="statusFilter" class="form-select" name="statusFilter">
        <option
            v-for="el in statusOption"
            :key="el.value"
            :label="el.label"
            :value="el.value"
        />
      </select>
    </div>
    <div class="me-2" style="display:inline-block;">
      <label class="form-label" for="lockFilter">Bloccata: </label>
      <select v-model="lockFilter" class="form-select" name="lockFilter">
        <option
            v-for="el in lockOption"
            :key="el.value"
            :label="el.label"
            :value="el.value"
        />
      </select>
    </div>
    <div class="me-2" style="display:inline-block;">
      <label class="form-label" for="attendanceFilter">Presenza: </label>
      <select v-model="attendanceFilter" class="form-select" name="attendanceFilter">
        <option
            v-for="el in attendanceOption"
            :key="el.value"
            :label="el.label"
            :value="el.value"
        />
      </select>
    </div>
    <div class="me-2" style="display:inline-block;">
      <label class="form-label" for="searchFilter">Ricerca Rapida: </label>
      <input type="text" v-model="searchFilter" class="form-control" name="searchFilter"/>
    </div>
    <div class="me-2 px-2" style="display:inline-block;"
         @click.stop.prevent="typeFilter = null; statusFilter = null; attendanceFilter = null; searchFilter = ''; lockFilter = null"
         v-if="typeFilter !== null || statusFilter !== null || attendanceFilter !== null || searchFilter !== '' || lockFilter !== null">
      <i class="fa-solid fa-filter-circle-xmark"></i> Pulisci
    </div>
  </div>
  <div class="flexbox mt-2">
    <EventCard v-for="event in list" v-bind:key="event.id" :event="event"/>
  </div>
</template>